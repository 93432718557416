import React from 'react';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { TestimonialSlider } from '../../testimonial/testimonial-slider';
import { TestimonialSlide } from '../../testimonial/testimonial-slide';
import { BlockContent } from '../../block-content/block-content';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import styles from './testimonial-section.module.scss';

/**
 * @typedef {Object} TestimonialSectionProps
 *
 * @param {TestimonialSectionProps} props
 */
export const TestimonialSection = ({ node }) => {
  const titleBlocks = node.title;
  return (
    <Container variant="thin" className={styles.specialContainer}>
      {titleBlocks && (
        <div className={styles.heading}>
          <BlockContent blocks={titleBlocks} />
        </div>
      )}
      <TestimonialSlider>
        {node.testimonials.map((testimonialItem) => {
          const { personImage, testimonial, personName, company, _id } = testimonialItem;
          return (
            <TestimonialSlide
              key={`${_id}-key`}
              isSingle={node.testimonials.length === 1}
              personName={personName}
              company={company}
              testimonialNode={<BlockContent blocks={testimonial} />}
              imageNode={
                !!personImage && (
                  <PictureAsset
                    image={personImage}
                    isFluid
                    width={160}
                    aspectRatio={1}
                    maxWidth={160}
                    breakPoints={[160]}
                  />
                )
              }
            />
          );
        })}
      </TestimonialSlider>
    </Container>
  );
};
