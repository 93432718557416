import React, { useContext } from 'react';
import clsx from 'clsx';
import styles from './cta-card.module.scss';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';

import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';
import { getUrlFromNavItem } from 'gatsby-theme-sanity-evelan/src/lib/helpers';
import { LayoutContext } from 'gatsby-theme-sanity-evelan/src/contexts/LayoutContext';

export const CtaCard = ({ cards }) => {
  const { startPageId } = useContext(LayoutContext);
  return (
    <div className={styles.cardWrapper}>
      {cards.cards.map((card, idx) => {
        const {
          title,
          text,
          image,
          cta: {
            label: ctaLabel,
            reference: { _id: ctaPageId, _type: ctaPageType, slug: { current: ctaSlug } } = {},
          } = {},
        } = card;
        return (
          title && (
            <div key={idx} className={styles.cardHolder}>
              <div className={styles.cardBackgroundHolder}>
                <PictureAsset image={image} width="100%" height="100%" isFluid />
              </div>
              <div className={styles.cardBackgroundHover} />
              <div className={styles.cardContentHolder}>
                <h3>{title}</h3>
                <p>{text}</p>
                <div className={styles.ctaBtnHolder}>
                  <LinkButton
                    className={clsx(styles.ctaText, styles.ctaBtn)}
                    variant="primary"
                    to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: ctaSlug }, startPageId)}
                  >
                    {ctaLabel}
                  </LinkButton>
                </div>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};
