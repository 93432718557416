import React, { useContext } from 'react';
import clsx from 'clsx';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';
import { getUrlFromNavItem } from 'gatsby-theme-sanity-evelan/src/lib/helpers';
import { LayoutContext } from 'gatsby-theme-sanity-evelan/src/contexts/LayoutContext';
import { BlockContent } from '../../block-content/block-content';
import { Container } from 'gatsby-theme-sanity-evelan';
import styles from './teaser-box-section.module.scss';

export const TeaserBoxSection = ({ node = {} }) => {
  const { startPageId } = useContext(LayoutContext);
  const { spacing, title } = node;

  return (
    <div>
      {title && (
        <Container variant="thin" className={styles.teaserTitle}>
          <BlockContent blocks={title} />
        </Container>
      )}
      <div className={clsx(styles.teaserWrapper, spacing && styles.teaserSpacing)}>
        {Object.values(node)
          .filter((item) => item.hasOwnProperty('_type') && item._type === 'card')
          .map((item, idx) => {
            const {
              title,
              text,
              image,
              cta: {
                label: ctaLabel,
                reference: { _id: ctaPageId, _type: ctaPageType, slug: { current } = {} } = {},
              } = {},
            } = item;
            return (
              <div key={`teaser-${idx}`} className={styles.teaserHolder}>
                <div className={styles.teaserBackgroundHolder}>
                  <PictureAsset image={image} width="100%" height="100%" isFluid />
                </div>
                <div className={image ? styles.teaserHover : styles.teaserSecondary} />
                <div className={styles.teaserContentHolder}>
                  <div className={styles.textHolder}>
                    <h3>{title}</h3>
                    <p>{text}</p>
                  </div>
                  <div className={clsx(styles.ctaBtnHolder, !image && styles.buttonSecondary)}>
                    <LinkButton
                      className={clsx(styles.ctaText, styles.ctaBtn)}
                      variant="primary"
                      to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: current }, startPageId)}
                    >
                      {ctaLabel}
                    </LinkButton>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
