import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { chunk } from 'gatsby-theme-sanity-evelan/src/lib/helpers';
import { BlockContent } from 'gatsby-theme-sanity-evelan/src/components/block-content/block-content';
import styles from './reasons-why.module.scss';

export function ReasonsWhy({ teaser = false, limit }) {
  const {
    allSanityReasonWhy: { nodes },
  } = useStaticQuery(graphql`
    {
      allSanityReasonWhy(sort: { fields: number }) {
        nodes {
          title
          number
          description: _rawDescription
        }
      }
    }
  `);

  let limitedNodes = [];
  if (limit && typeof limit === 'number' && limit < nodes.length) {
    limitedNodes = nodes.slice(0, limit);
  } else {
    limitedNodes = nodes;
  }

  const chunkedNodes = chunk(limitedNodes, 8);

  return (
    <div className={teaser ? styles.reasonTeaserContainer : styles.reasonContainer}>
      {chunkedNodes.map((nodeBatch, index) => (
        <div key={`${index}_key`} className={styles.reasonBatchContainer}>
          {nodeBatch.map((node) => (
            <div key={node.number} className={styles.reasonItem}>
              <div className={styles.number}>
                <h4>{node.number}</h4>
              </div>
              <div className={styles.textContainer}>
                <h4>{node.title}</h4>
                <BlockContent blocks={node.description} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
