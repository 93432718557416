import React from 'react';
import { HeroVideoSection } from 'gatsby-theme-sanity-evelan/src/components/sections/hero-video-section/hero-video-section';
import { BlockContentSection } from 'gatsby-theme-sanity-evelan/src/components/sections/block-content-section/block-content-section';
import { BlockTextImageSection } from 'gatsby-theme-sanity-evelan/src/components/sections/block-text-image-section/block-text-image-section';
import { IconListSection } from 'gatsby-theme-sanity-evelan/src/components/sections/icon-list-section/icon-list-section';
import { BlockTextImageBackgroundSection } from 'gatsby-theme-sanity-evelan/src/components/sections/block-text-image-background-section/block-text-image-background-section';
import { BlogTeaserSection } from 'gatsby-theme-sanity-evelan/src/components/sections/blog-teaser-section/blog-teaser-section';

import { BlockTextWithCta } from './block-text-with-cta/block-text-with-cta';
import { ReasonsWhySection } from './reasons-why-section/reasons-why-section';
import { ReasonsWhyTeaserSection } from './reasons-why-teaser-section/reasons-why-teaser-section';
import HeroTextImageBackground from './hero-text-image-background/hero-text-image-background';
import { TestimonialSection } from './testimonial-section/testimonial-section';
import { ProcessSection } from './process-section/process-section';
import { TeaserBoxSection } from './teaser-box-section/teaser-box-section';
import { CompanyFactsSection } from './company-facts-section/company-facts-section';

export function renderSection(sectionNode) {
  const sectionKey = `section-${sectionNode._key}`;

  switch (sectionNode._type) {
    case 'heroTextImageBackground':
      return <HeroTextImageBackground key={sectionKey} node={sectionNode} />;
    case 'heroVideoSection':
      return <HeroVideoSection key={sectionKey} node={sectionNode} />;
    case 'blockContentSection':
      return <BlockContentSection key={sectionKey} node={sectionNode} />;
    case 'blockTextImageSection':
      return <BlockTextImageSection key={sectionKey} node={sectionNode} />;
    case 'iconListSection':
      return <IconListSection key={sectionKey} node={sectionNode} />;
    case 'blockTextImageBackgroundSection':
      return <BlockTextImageBackgroundSection key={sectionKey} node={sectionNode} />;
    case 'reasonWhyTeaserSection':
      return <ReasonsWhyTeaserSection key={sectionKey} node={sectionNode} />;
    case 'blogTeaserSection':
      return <BlogTeaserSection key={sectionKey} node={sectionNode} />;
    case 'reasonWhySection':
      return <ReasonsWhySection key={sectionKey} node={sectionNode} />;
    case 'blockTextWithCta':
      return <BlockTextWithCta key={sectionKey} node={sectionNode} />;
    case 'testimonialSection':
      return <TestimonialSection key={sectionKey} node={sectionNode} />;
    case 'processSection':
      return <ProcessSection key={sectionKey} node={sectionNode} />;
    case 'teaserBoxSection':
      return <TeaserBoxSection key={sectionKey} node={sectionNode} />;
    case 'companyFactsSection':
      return <CompanyFactsSection key={sectionKey} node={sectionNode} />;
    default:
      // eslint-disable-next-line no-console
      console.info('Section renderer did not render this section', sectionNode);
      return null;
  }
}
