import React from 'react';
import { animated as a, useSprings, useTrail } from 'react-spring';
import { useInView } from 'react-intersection-observer';

import { Container } from 'gatsby-theme-sanity-evelan';
import { BlockContent } from '../../block-content/block-content';
import { BreakOutContainer } from 'gatsby-theme-sanity-evelan/src/components/container/break-out-container';
import { CtaCard } from '../../block-content/cta-card';

import styles from './company-facts-section.module.scss';

export function CompanyFactsSection({ node }) {
  const {
    content = [],
    firstContent = {},
    secondContent = {},
    thirdContent = {},
    factsTitle = '',
    companyFacts = [],
  } = node;
  const numRegex = /\d{1,3}(?=(\d{3})+(?!\d))/g;
  const cards = { cards: [firstContent, secondContent, thirdContent] };

  const [companyFactsRef, companyFactsInView] = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const countProps = useSprings(
    companyFacts.length,
    companyFacts.map((fact) => ({
      num: companyFactsInView ? fact.count : 0,
      from: {
        num: 0,
      },
      config: { mass: 100, friction: 200, tension: 200, clamp: true },
    }))
  );

  const [trail, setTrail] = useTrail(companyFacts.length, () => ({
    transform: `translateY(2em)`,
    opacity: 0,
  }));

  if (companyFactsInView) {
    setTrail({
      transform: `translateY(0em)`,
      opacity: 1,
    });
  }

  return (
    <Container variant="thin" className={styles.container}>
      <div className={styles.holder}>
        <div className={styles.blockText}>
          <BlockContent blocks={content} />
          <div ref={companyFactsRef} className={styles.wrapper}>
            {factsTitle && <h3>{factsTitle}</h3>}
            {companyFacts.map((fact, index) => {
              const { title, equality, key } = fact;

              return (
                <a.div style={trail[index]} key={`fact-${index}${key}`} className={styles.factsHolder}>
                  <h1 className={styles.count}>
                    {equality === 'more' && <span>&gt;</span>}
                    <a.span>
                      {countProps[index].num.interpolate((val) => Math.floor(val).toString().replace(numRegex, '$&.'))}
                    </a.span>
                  </h1>
                  <span className={styles.title}>{title}</span>
                </a.div>
              );
            })}
          </div>
        </div>
        <div className={styles.cardHolder}>
          <BreakOutContainer>
            <CtaCard cards={cards} />
          </BreakOutContainer>
        </div>
      </div>
    </Container>
  );
}
