import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { BlockContent } from 'gatsby-theme-sanity-evelan/src/components/block-content/block-content';
import PostCard from '../../blog/post-card/post-card';
import TrailAnimation from 'gatsby-theme-sanity-evelan/src/components/animate/TrailAnimation';
import { formatDate } from 'gatsby-theme-sanity-evelan/src/lib/helpers';
import styles from './blog-teaser-section.module.scss';

export function BlogTeaserSection({ node }) {
  const { title, description } = node;

  const { latestPost } = useStaticQuery(
    graphql`
      query {
        latestPost: allSanityPost(limit: 3, sort: { order: DESC, fields: publishedAt }) {
          nodes {
            category {
              name
              color {
                hex
              }
            }
            body: _rawBody(resolveReferences: { maxDepth: 10 })
            title
            publishedAt
            mainImage: _rawMainImage(resolveReferences: { maxDepth: 10 })
            slug {
              current
            }
          }
        }
      }
    `
  );

  return (
    <Container variant="thin" className={styles.container}>
      <BlockContent blocks={title} />
      {description && <div className={styles.description}>{description}</div>}
      <TrailAnimation component={<div />} variant="SlideUpSlow" visibleAt={25}>
        <div className={styles.blogTeaserWrapper}>
          {latestPost.nodes.map((post, index) => (
            <PostCard
              key={index}
              body={post.body}
              mainImage={post.mainImage}
              publishedDate={formatDate(post.publishedAt)}
              category={post.category.name}
              title={post.title}
              color={post.category.color.hex}
              slug={`${post.slug.current}`}
            />
          ))}
        </div>
      </TrailAnimation>
    </Container>
  );
}
