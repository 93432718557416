import React from 'react';
import clsx from 'clsx';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { BlockContent } from 'gatsby-theme-sanity-evelan/src/components/block-content/block-content';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import { ReasonsWhy } from '../../reason-why/reasons-why';
import styles from './reasons-why-section.module.scss';

export function ReasonsWhySection({ node }) {
  const { content, image = {} } = node;

  return (
    <div>
      <div className={styles.overflowWrapper}>
        <Container variant="thin" className={clsx(styles.container, styles.customThin)}>
          <div className={styles.text}>
            <BlockContent blocks={content} />
          </div>
          <div className={styles.reasonContent}>
            <ReasonsWhy teaser={false} />
          </div>
          <div className={styles.imageWrapper}>
            <div className={styles.imageContainer}>
              <PictureAsset className={styles.image} image={image} alt={image.alt} isFluid width="100%" />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
