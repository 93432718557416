import React from 'react';
import styles from './block-text-with-cta.module.scss';
import { BlockContent } from 'gatsby-theme-sanity-evelan/src/components/block-content/block-content';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { BreakOutContainer } from 'gatsby-theme-sanity-evelan/src/components/container/break-out-container';
import { CtaCard } from '../../block-content/cta-card';

export const BlockTextWithCta = ({ node }) => {
  const { card, content } = node;
  return (
    <div className={styles.blockTextWithCtaWrapper}>
      <Container variant="thin" className={styles.container}>
        <div className={styles.blockTextWithCtaHolder}>
          <div className={styles.blockText}>
            <BlockContent blocks={content} />
          </div>
          <div className={styles.blockTextCardHolder}>
            <BreakOutContainer>
              <CtaCard cards={card} />
            </BreakOutContainer>
          </div>
        </div>
      </Container>
    </div>
  );
};
