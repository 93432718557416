import React from 'react';
import clsx from 'clsx';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { BlockContent } from 'gatsby-theme-sanity-evelan/src/components/block-content/block-content';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import styles from './block-text-image-section-custom.module.scss';

export function BlockTextImageSection({ node }) {
  const { content, image = {}, imagePlacement, imagePosition } = node;
  const placement = imagePlacement;
  const position = imagePosition;

  return (
    <div className={styles.overflowWrapper}>
      <Container
        variant="thin"
        className={clsx(styles.container, placement === 'right' && styles.right, placement === 'left' && styles.left)}
      >
        <div className={styles.text}>
          <BlockContent blocks={content} />
        </div>
        <div className={styles.imageWrapper}>
          <div
            className={clsx(
              styles.imageContainer,
              position === 'overflow' && styles.overflow,
              position === 'inside' && styles.inside
            )}
          >
            <PictureAsset
              className={styles.image}
              image={image}
              alt={image.alt}
              maxWidth={position === 'overflow' ? 1200 : 800}
              isFluid
              width="100%"
            />
          </div>
        </div>
      </Container>
    </div>
  );
}
