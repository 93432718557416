import React from 'react';

import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { BlockContent } from 'gatsby-theme-sanity-evelan/src/components/block-content/block-content';

import styles from './hero-text-image-background.module.scss';

const HeroTextImageBackground = ({ node }) => {
  const { content, image = {} } = node;
  return (
    <div className={styles.imageBackgroundWrapper}>
      <div className={styles.imageWrapper}>
        <div className={styles.imageOverlay} />
        <PictureAsset className={styles.image} image={image} alt={image.alt} isFluid width="100%" height="100%" />
      </div>
      <div className={styles.contentWrapper}>
        <Container variant="thin">
          <div className={styles.contentHolder}>
            <div className={styles.text}>
              <BlockContent blocks={content} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default HeroTextImageBackground;
