import React from 'react';
import clsx from 'clsx';

import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { PictureAsset } from 'gatsby-theme-sanity-evelan';
import DashedToRight from '../../../../images/process-section/dashed-to-right.svg';
import DashedToLeft from '../../../../images/process-section/dashed-to-left.svg';
import DashedToRightDark from '../../../../images/process-section/dashed-to-right-dark.svg';

import styles from './process-section.module.scss';

export const ProcessSection = ({ node }) => {
  const { steps } = node;
  const isOdd = (num) => {
    return !!(num & 1);
  };

  const renderSVG = (i) => {
    if (i === steps.length - 2) {
      return DashedToRightDark;
    } else if (isOdd(i)) {
      return DashedToLeft;
    } else {
      return DashedToRight;
    }
  };

  return (
    <Container variant="thin">
      <div className={styles.wrapper}>
        {steps.map(({ title, description, image }, i) => (
          <div key={i}>
            {i !== steps.length - 1 && (
              <img
                src={renderSVG(i)}
                className={clsx(styles.dashLines, isOdd(i) && styles.dashLeft, !isOdd(i) && styles.dashRight)}
                alt="Dashed Step Icon"
              />
            )}
            <div className={styles.imageWrapper}>
              <PictureAsset className={styles.image} image={image} alt={image.alt} isFluid width="100%" height="100%" />
            </div>
            <h2>{`${i + 1}. ${title}`}</h2>
            <p>{description}</p>
          </div>
        ))}
      </div>
    </Container>
  );
};
