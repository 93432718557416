import React, { useState } from 'react';
import clsx from 'clsx';
import { SwipeableSingle } from 'gatsby-theme-sanity-evelan';
import { TestimonialArrow } from '../svg-icons/testimonial-arrow';
import styles from './testimonial-slider.module.scss';

export const TestimonialSlider = ({ children = [] }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  let output =
    children.length > 1 ? (
      <>
        <button
          onClick={() => activeSlide > 0 && setActiveSlide(activeSlide - 1)}
          className={clsx(styles.nav, styles.prevSlide, activeSlide === 0 && styles.hide)}
        >
          <TestimonialArrow />
        </button>
        <div className={styles.sliderContainer}>
          <SwipeableSingle activeIndex={activeSlide} setActiveIndex={setActiveSlide}>
            {children}
          </SwipeableSingle>
        </div>
        <button
          onClick={() => activeSlide < children.length - 1 && setActiveSlide(activeSlide + 1)}
          className={clsx(styles.nav, styles.nextSlide, activeSlide === children.length - 1 && styles.hide)}
        >
          <TestimonialArrow />
        </button>
      </>
    ) : (
      <>{children}</>
    );

  return <div className={clsx(styles.testimonialSlider, children.length === 1 && styles.single)}>{output}</div>;
};
