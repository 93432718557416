import React from 'react';
import styles from './testimonial-slide.module.scss';
import clsx from 'clsx';

export const TestimonialSlide = ({
  className = '',
  imageNode,
  testimonialNode,
  personName,
  company,
  isSingle = false,
}) => {
  return (
    <div className={clsx(styles.slide, isSingle && styles.single, !imageNode && styles.full, className)}>
      {!!imageNode && <div className={styles.imageWrapper}>{imageNode}</div>}
      <div className={styles.testimonialText}>{testimonialNode}</div>
      <div className={styles.clientInfo}>
        <h5>{personName}</h5>
        <p>{company}</p>
      </div>
    </div>
  );
};
