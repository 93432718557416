import React, { useContext } from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import { Container } from 'gatsby-theme-sanity-evelan/src/components/container/container';
import { LinkButton } from 'gatsby-theme-sanity-evelan/src/components/button/link-button';
import { LayoutContext } from 'gatsby-theme-sanity-evelan/src/contexts/LayoutContext';
import { getUrlFromNavItem } from 'gatsby-theme-sanity-evelan/src/lib/helpers';
import { ReasonsWhy } from '../../reason-why/reasons-why';

import styles from './reasons-why-teaser-section.module.scss';

export function ReasonsWhyTeaserSection({ node }) {
  const {
    title,
    cta: {
      label: ctaLabel,
      reference: {
        id: ctaPageId,
        _type: ctaPageType,
        slug: { current: ctaSlug },
      },
    },
  } = node;
  const { startPageId } = useContext(LayoutContext);

  const { laptop } = useStaticQuery(graphql`
    query {
      laptop: file(name: { eq: "laptop" }) {
        childImageSharp {
          fluid(
            quality: 100
            maxWidth: 2000
            srcSetBreakpoints: [340, 768, 2000]
            traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "transparent", color: "#8b181b" }
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className={styles.reasonTeaserWrapper}>
      <Container variant="thin" className={styles.reasonTeaserHolder}>
        <h2 className={styles.heading}>{title}</h2>
        <div className={styles.hider}>
          <ReasonsWhy teaser={true} limit={4} />
        </div>
        <LinkButton
          variant="primary"
          className={styles.btnCta}
          to={getUrlFromNavItem({ id: ctaPageId, type: ctaPageType, slug: ctaSlug }, startPageId)}
        >
          {ctaLabel}
        </LinkButton>
        <div className={styles.laptop}>
          <Img fluid={laptop.childImageSharp.fluid} loading="auto" alt="Training Areas" />
        </div>
      </Container>
    </div>
  );
}
